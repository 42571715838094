.container {
  margin: 10px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  max-width: calc(var(--cell-size) * 7);
  width: 300px;
  font-family: system-ui, -apple-system, 'Segoe UI', Roboto, Ubuntu, Cantarell, 'Noto Sans', sans-serif, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.cell {
  font-size: 18px;
  text-align: center;
  padding: 6px;
  color: #000;
  width: 24px;
  height: auto;
  margin: 4px 0;
}
.actual {
  border: solid 2px red;
  border-radius: 15%;
}
.metal-plastik {
  background: #f6f600;
}
.papier {
  background: #9fbbe5;
}
.szklo {
  background: #92d050;
}
.bio {
  background: #c37e39;
}
.zmieszane {
  background: #b5b3b3;
}
/*
.cell-1 {
}
.cell-2 {
}
.cell-3 {
}
.cell-4 {
}
.cell-5 {
}*/
.cell-6 {
  color: #999;
}
.cell-7 {
  color: red;
}
.black {
  color: #000;
}
.day-name {
  grid-template-rows: 1fr;
}
.month-name {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 5fr 1fr;
}
.month {
  text-align: center;
  margin: auto;
  width: max-content;
  font-size: 28px;
}
.btn {
  width: 35px;
}

.btn.street {
  width: fit-content;
}

.circle-1-2 {
  clip-path: polygon( 0 0, 50% 0, 50% 100%, 0 100%);
  z-index: 2;
}
.circle-2-2 {
  z-index: 1;
}
.circle-1-3 {
  clip-path: polygon(55% 0, 55% 50%, 0 75%, 0% 0%);
  z-index: 3;
}
.circle-2-3 {
  clip-path: polygon(100% 0, 100% 70%, 44% 49%, 44% 0);
  z-index: 2;
}
.circle-3-3 {
  z-index: 1;
}
.circle-1-4 {
  clip-path: polygon(0 0, 50% 0, 50% 50%, 0 50%);
  z-index: 4;
}
.circle-2-4 {
  clip-path: polygon(50% 0, 100% 0, 100% 50%, 50% 50%);
  z-index: 3;
}
.circle-3-4 {
  clip-path: polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%);
  z-index: 2;
}
.circle-4-4 {
  z-index: 1;
}

.circle {
  width: 36px;
  height: 36px;
  position: absolute;
  /*z-index: 1;*/
  top: 0px;
  left: 0px;
  border-radius: 15%;
}

.day {
  position: relative;
}

.day span {
  z-index: 99;
  position: relative;
}

.search {
  grid-template-rows: 1fr;
  grid-template-columns: 2fr 5fr;
}
.search-result {
  text-align: right;
}


.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
}
.dropdown-content-search {
  position: absolute;
  z-index: 999;
}

.dropdown-content>p {
  background-color: #f9f9f9;
  padding: 12px 16px;
  z-index: 1;
  margin: 0;
  cursor: pointer;
}
.dropdown-txt {
  background-color: #f9f9f9;
  padding: 12px 16px;
  z-index: 1;
  margin: 0;
  cursor: pointer;
}

.dropdown-txt:hover{
  background-color: #cdcdcd;
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.findStreet {
  text-align: end;
}

.container-loader{
  position: absolute;
  top: 50%;
  left: 50%;
  /*transition: -50% -50%;*/
  translate: -50% -50%;
}


.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #3498db;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
  display:flex;
  justify-content: center;
  align-items: center;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 